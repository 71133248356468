<template>
  <div>
    <div class="search-box d-flex justify-content-between">
      <div>
        <p class="mb-0 pagination">
          {{ lbl['pagination-show-text'] }}
          {{ dataDetailPerPage }}
          {{ lbl['from'] }}
          {{ dataDetailLength }}
          {{ lbl['smarttarget-Items'] }}
        </p>
      </div>
      <div>
        <a-input-search
          v-model="filter"
          :placeholder="lbl['search_campaign']"
          type="text"
        />
      </div>
    </div>
    <!-- Table -->
    <div class="pt-3 table-box selectrewardTable">
      <a-table
        :columns="currentLocale == 'th' ? columnsDetailTH : columnsDetail"
        :data-source="dataDetailFiltered"
        rowKey="ID"
        :rowClassName="() => 'pointer'"
        :customRow="handleEditCampaings"
        :pagination="{ defaultPageSize: 5 }"
        @change="onchangeTable"
      >
        <template slot="reward" slot-scope="text, record">
          <div class="rewardContainer d-flex">
            <a-radio
              class="mr-3"
              :checked="selectedReward.ID === record.ID"
              :value="record.id"
            ></a-radio>
            <div class="rewardImg">
              <img :src="record.ImageUrl" alt="" />
            </div>
            <div class="rewardDetail text-left">
              {{ currentLocale == 'th' ? record.Name : record.NameEN }}
            </div>
          </div>
        </template>
        <template slot="date" slot-scope="text, record">
          {{ formatDateString(record.StartDate) }} -
          {{ formatDateString(record.EndDate) }}
        </template>
      </a-table>
      <!-- <div class="text-right mt-3">
          <div class="show-page">
            {{ lbl['pagination-show-text'] }} {{ currentDetail }}
            {{ lbl['pagination-to-text'] }} {{ Math.ceil(totalDetail / 10) }}
          </div>
          <a-pagination
            :total="totalDetail"
            :default-current="1"
            :item-render="itemRender"
            @change="onChangePageDetail"
          />
        </div> -->
    </div>
    <!-- Table -->
    <!-- Create Rewar -->
    <div class="row" v-if="!dataDetail[0]">
      <div class="col-12 text-center box-create-reward">
        <img src="@/assets/images/inbox.svg" alt="" width="60" height="60" />
        <p class="title-create">{{ lbl['no_create_reward'] }}</p>
        <p class="detail-create">{{ lbl['detail_no_create_reward'] }}</p>
        <router-link
          :to="'/PlanReward/BurnList?backTo=' + $route.query.id"
          class="btn-create-reward"
        >
          <img
            src="@/assets/images/addplus.svg"
            alt=""
            width="24"
            height="24"
          />
          <span class="ml-2">
            {{ lbl['btn_create_reward-smarttarget'] }}
          </span>
        </router-link>
      </div>
    </div>
    <!-- Create Rewar -->
    <!-- Footer -->
    <div class="row footer-btn">
      <div class="col-12 mt-4 mb-2">
        <a class="btn-back mr-3" @click="closeModal()">
          {{ lbl['btn_back'] }}
        </a>
        <button
          :disabled="!selectedReward.ID"
          @click="onSelectReward()"
          class="btn-use-reward ml-3"
        >
          {{ lbl['btn_use_reward'] }}
        </button>
      </div>
    </div>
    <!-- Footer -->
  </div>
</template>

<script>
import _ from 'lodash'
import Mixin from '@/mixin/Mixin'
import Locale from '@/helper/locale.js'
import BzbsMamageUser from '@/core/Import/service/BzbsMamageUser'
import Account from '@/helper/AccountHelper.js'
import moment from 'moment'
const columnsDetail = [
  {
    title: 'Rewards',
    dataIndex: 'reward',
    key: 'reward',
    width: 320,
    scopedSlots: { customRender: 'reward' },
  },
  {
    title: 'Period',
    dataIndex: 'active_period',
    key: 'active_period',
    sorter: (a, b) => a.StartDate - b.StartDate,
    align: 'left',
    scopedSlots: { customRender: 'date' },
  },
  {
    title: 'Point to use',
    dataIndex: 'PointPerUnit',
    key: 'PointPerUnit',
    sorter: (a, b) => a.PointPerUnit - b.PointPerUnit,
    align: 'left',
  },
]
const columnsDetailTH = [
  {
    title: 'รางวัล',
    dataIndex: 'reward',
    key: 'reward',
    width: 320,
    scopedSlots: { customRender: 'reward' },
  },
  {
    title: 'ช่วงเวลา',
    dataIndex: 'active_period',
    key: 'active_period',
    sorter: (a, b) => a.StartDate - b.StartDate,
    align: 'left',
    scopedSlots: { customRender: 'date' },
  },
  {
    title: 'จำนวนแต้มที่ต้องใช้',
    dataIndex: 'PointPerUnit',
    key: 'PointPerUnit',
    sorter: (a, b) => a.PointPerUnit - b.PointPerUnit,
    align: 'left',
  },
]

export default {
  name: 'userCustomerLog',
  mixins: [Mixin],
  props: {
    selectedRewardhandler: {
      type: Function,
    },
    dataDetail: {
      type: Array,
    },
  },
  data: function () {
    return {
      data: [],
      total: null,
      columnsDetail,
      columnsDetailTH,
      totalDetail: null,
      currentDetail: 1,
      filters: {
        user_id: '',
      },
      storetype: '',
      selectedReward: '',
      filter: '',
      currentPage: 1,
      locale: Locale,
      currentLocale: 'th',
    }
  },
  computed: {
    dataDetailFiltered() {
      if (this.filter === '') return this.dataDetail
      return this.dataDetail.filter(i => {
        if (!i.Name) i.Name = ''
        if (!i.NameEN) i.NameEN = ''
        return (
          i.PointPerUnit + '' === this.filter ||
          i.Name.includes(this.filter) ||
          i.NameEN.includes(this.filter) ||
          i.ID + '' === this.filter
        )
      })
    },
    dataDetailLength() {
      return this.dataDetailFiltered.length
    },
    dataDetailPerPage() {
      let lastItemPage = this.dataDetailLength % 5
      let lastPage = Math.ceil(this.dataDetailLength / 5)
      if (this.currentPage === lastPage) {
        return lastItemPage
      }
      return this.dataDetailLength < 5 ? this.dataDetailLength : 5
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'select-reward',
      onFieldsChange: prop => {
        if (prop.isFieldsTouched()) {
          this.formTouch = true
        }
      },
    })
  },
  created() {
    this.storetype = Account.getStoreType()
  },
  mounted() {
    this.currentLocale = Locale.getLocaleShort()
  },
  methods: {
    setLocale() {
      _.forEach(this.columnsDetail, column => {
        column.title = this.lbl[column.key]
      })
    },
    formatDateString(dateString, format = 'DD/MM/YYYY', calHours = null) {
      return moment(dateString * 1000)
        .add(calHours, 'hours')
        .format(format)
    },
    onchangeTable(e) {
      this.currentPage = e.current
    },
    onClickSelectReward(data) {
      console.log('selectedReward', data)
      this.selectedReward = data
    },
    onSelectReward() {
      Account.bzbsAnalyticTracking(
        'smart_target_create',
        'smart_target',
        'click_smart_target_select_reward',
        'on click',
      )
      this.closeModal()
      this.selectedRewardhandler(this.selectedReward)
    },
    onChangePageDetail(page) {
      this.currentDetail = page
      this.getSearchCustomerLog((page - 1) * 10)
    },
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a class="ant-pagination-item-link"> &#60;&#60; </a>
      } else if (type === 'next') {
        return <a class="ant-pagination-item-link"> &#62;&#62; </a>
      }
      return originalElement
    },
    handleEditCampaings(record, index) {
      return {
        attrs: {
          id: index,
        },
        on: {
          click: () => {
            this.onClickSelectReward(record)
          },
        },
      }
    },
    handleSearch() {
      console.log('columnsDetail : ', this.columnsDetail)
      _.forEach(this.columnsDetail, column => {
        if (column.dataIndex == 'Point' && this.storetype == 'stamp') {
          column.className = 'd-none'
        }
      })

      this.currentDetail = 1
      this.getSearchCustomerLog((this.currentDetail - 1) * 10)
    },

    getSearchCustomerLog(skip) {
      this.handleLoading(true)

      return new Promise(resolve => {
        BzbsMamageUser.getSearchUserCustomerLog(this.filters.user_id)
          .then(res => {
            this.handleLoading(false)
            console.log('res : ', res)
            this.totalDetail = res.data.length
            const data_skip = res.data.slice(skip)
            this.dataDetail = data_skip.slice(0, 10)

            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getSearchUserCustomerLog error', error)
            resolve(error)
          })
      })
    },
    closeModal() {
      Account.bzbsAnalyticTracking(
        'smart_target_create',
        'smart_target',
        'click_smart_target_select_reward_cancel',
        'on click',
      )
      this.$emit('closeModal', true)
    },
  },
}
</script>
<style lang="scss">
.selectrewardTable {
  .pointer {
    cursor: pointer;
  }
  .ant-table .ant-table-tbody > tr > td {
    border-left: none !important;
    font-size: 14px !important;
    text-align: left;
    padding-left: 16px;
  }
  .ant-table .ant-table-tbody > tr > td:last-child {
    border-right: none !important;
  }
  .rewardContainer {
    gap: 16px;
    .rewardImg {
      width: 50px;
      height: 50px;
      border: 1px solid #eee;
      border-radius: 6px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .rewardDetail {
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>
