import Vue from 'vue';
import BzbsApi from '@/core/Http/BzbsApi.js';
import Cache from '@/helper/Cache.js';
/**
 * @method validateAdjustFile
 */
export default {
	KEY_COOKIE_USER: 'CRMUser',
	config: function() {
		return Vue.bzbsConfig;
	},
	token: function() {
		return Cache.get(this.KEY_COOKIE_USER).Token;
	},
	agency_id: function() {
		return Vue.$cookies.get(this.KEY_COOKIE_USER).AgencyId
			? Vue.$cookies.get(this.KEY_COOKIE_USER).AgencyId
			: this.config().agency_id;
	},
	getSearchUserCustomerLog: function(userid) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmplusoffice/userlog?device_app_id=' +
			this.config().client_id +
			'&agencyId=' +
			this.agency_id() +
			'&userId=' +
			userid;
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	}
};
