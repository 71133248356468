<template>
  <div>
    <div
      :class="['backDrop', isDeleteModal ? 'isShow' : '']"
      @click="toggle(false)"
    ></div>
    <div :class="['deleteModal', isDeleteModal ? 'isShow' : '']">
      <div class="modalHeader">
        <img :src="require('@/assets/images/delete_outline.png')" alt="" />
        {{ lbl['smart-target-deletetitle'] }}
      </div>
      <div class="subText">{{ lbl['smart-target-deletesubtitle'] }}</div>
      <div class="footerAction">
        <button class="closeBtn" @click="toggle(false)">
          {{ lbl['user-permissions-no'] }}
        </button>
        <button class="saveBtn" @click="cfDelete()">
          {{ lbl['user-permissions-yes'] }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
export default {
  mixins: [Mixin],
  props: {
    isDeleteModal: {
      type: Boolean,
      default: false,
    },
    toggle: {
      type: Function,
    },
    cfDelete: {
      type: Function,
    },
  },
}
</script>

<style lang="scss" scoped>
.deleteModal {
  width: 530px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  padding: 34px 34px 34px 48px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  z-index: 729;
  img {
    position: absolute;
    top: 51%;
    transform: translateY(-50%);
    left: -28px;
  }
  &.isShow {
    opacity: 1;
    visibility: visible;
  }
  .modalHeader {
    font-size: 24px;
    position: relative;
  }
  .subText {
    font-size: 14px;
  }
  .footerAction {
    text-align: end;
    button {
      padding: 8px 16px;
      border-radius: 5px;
      margin: 24px 8px 0 0;
    }
    .closeBtn {
      background-color: white;
      border: solid 1px #e0e0e0;
    }
    .saveBtn {
      color: white;
      background-color: #ff5252;
      border: solid 1px #ff5252;
    }
  }
}
.backDrop {
  z-index: 729;
  &.isShow {
    opacity: 1;
    visibility: visible;
  }
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
