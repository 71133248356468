<template>
  <div>
    <div class="row box-artwork">
      <a-skeleton v-if="loading" />
      <a-radio-group v-if="!loading" v-model="value" style="display: contents">
        <div
          v-for="(item, index) in imageArtWork"
          :key="index"
          class="col-3 pr-0 py-3"
        >
          <a-radio :value="item.image_url">
            <img
              :src="item.image_url + '?t=' + new Date().getTime()"
              alt=""
              class="w-100"
            />
          </a-radio>
          <a class="preview" @click="handleOpenPreview(item)">
            <img
              class="border-0"
              src="@/assets/images/fullscreen.svg"
              alt=""
              width="18"
              height="18"
            />
          </a>
        </div>
      </a-radio-group>
    </div>
    <!-- Footer -->
    <div class="row footer-btn">
      <div class="col-12 mt-4 mb-2">
        <a class="btn-back mr-3" @click="closeModal()">
          {{ lbl['btn_back'] }}
        </a>
        <a class="btn-use-image ml-3" @click="onChangeImgPath()">
          {{ lbl['use_image'] }}
        </a>
      </div>
    </div>
    <!-- Footer -->
    <!-- Modal Preview -->
    <a-modal
      v-model="modal2VisiblePreview"
      class="preview-image"
      :footer="null"
      :class="[currentLocale]"
      centered
    >
      <img :src="dataImgPopup" class="w-100 modal-image" alt="" />
    </a-modal>
    <!-- Modal Preview -->
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Account from '@/helper/AccountHelper.js'
import BzbsSmarttarget from '@/core/Smarttarget/service/BzbsSmarttarget'
import Locale from '@/helper/locale.js'

export default {
  name: 'userCustomerLog',
  mixins: [Mixin],
  props: {
    selectArtWorkImg: {
      type: Function,
    },
  },
  data: function () {
    return {
      data: [],
      total: null,
      dataDetail: [],
      totalDetail: null,
      currentDetail: 1,
      dataImgPopup: '',
      filters: {
        user_id: '',
      },
      storetype: '',
      value: '',
      image: require('@/assets/images/blank-photo.png'),
      imageArtWork: [],
      modal2VisiblePreview: false,
      loading: false,
      currentLocale: Locale.getLocaleShort(),
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'select-artwork',
      onFieldsChange: prop => {
        if (prop.isFieldsTouched()) {
          this.formTouch = true
        }
      },
    })
  },
  created() {
    this.setLocale()
    if (this.$store.getters.getArtwork[0]) {
      this.imageArtWork = this.$store.getters.getArtwork
    } else {
      this.getArtWork()
    }
    this.storetype = Account.getStoreType()
    console.log('storetype : ', this.storetype)
  },
  methods: {
    setLocale() {
      //   _.forEach(this.columnsDetail, column => {
      //     column.title = this.lbl[column.key]
      //   })
    },
    getArtWork() {
      this.loading = true
      BzbsSmarttarget.getArtWork()
        .then(res => {
          let filterImg = res.data.data.artworks
            .filter(item => {
              return item.image_url.includes('template')
            })
            .map(item => {
              item.image_url = item.image_url.replace(':443', '') + '1040'
              return item
            })
          this.$store.dispatch('setArtWork', filterImg)
          this.imageArtWork = filterImg
        })
        .catch(err => console.log(err))
        .finally(() => (this.loading = false))
    },
    handleOpenPreview(item) {
      this.modal2VisiblePreview = true
      this.dataImgPopup = item.image_url
    },
    closeModal() {
      this.$emit('closeModal', true)
    },
    onChangeImgPath() {
      let selecttedImg = this.imageArtWork.filter(
        i => i.image_url === this.value,
      )
      this.selectArtWorkImg(selecttedImg[0].image_url)
      this.closeModal()
    },
  },
}
</script>
<style lang="scss">
.preview-image {
  .modal-image {
    padding: 30px 0px 15px;
  }
}
</style>
