import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Cache from '@/helper/Cache.js'
/**
 * @method getCRMPlusBurnCampaings
 * @method getSerialCodeList
 * @method postCRMPlusBurnCampaingsAdd
 * @method postCRMPlusBurnCampaingsEdit
 * @method postCRMPlusBurnCampaingsDuplicate
 * @method postBurnCampaingsSequence
 * @method postBurnCampaingsDelete
 * @method getSerialCodeListExportExcel
 * @method getCRMPlusEarnCampaings
 * @method postCRMPlusCreateEarnCampaings
 * @method postCRMPlusUpdateEarnCampaings
 * @method getCRMPlusStampCollection
 * @method getCRMPlusBurnCampaingsByAvailable
 * @method postCRMPlusStampCollection
 * @method postCRMPlusUpdateStampCollection
 */
export default {
  config: function () {
    return Vue.bzbsConfig
  },
  token: function () {
    return Cache.get('CRMUser').Token
  },
  agencyId: function () {
    return Cache.get('CRMUser').AgencyId
  },
  /**
   * Get user burn campaigns.
   * @param {object} obj
   * @return {promises} Profile response.
   */
  getCRMPlusBurnCampaings: function (active) {
    let params = {}
    if (active != null) {
      params = {
        device_app_id: this.config().client_id,
        agencyId: this.agencyId(),
        searchstatus: active ? 'active' : 'inactive',
      }
    } else {
      params = {
        device_app_id: this.config().client_id,
        agencyId: this.agencyId(),
      }
    }

    var strUrl = this.config().bzbsModuleUrl + '/crmplusburn'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  getSerialCodeList: function (objparams) {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agencyId(),
      top: 0,
      skip: objparams.skip,
    }

    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusburn/' +
      objparams.campaignID +
      '/serials'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  postCRMPlusBurnCampaingsAdd: function (file) {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agencyId(),
      name: file.Name,
      nameEN: file.NameEN,
      detail: file.Detail,
      detailEN: file.DetailEN,
      termsAndConditions: file.TermsAndConditions,
      conditionEN: file.ConditionEN,
      quantity: file.Quantity,
      startdate: file.StartDate,
      enddate: file.EndDate,
      active: file.Status,
      redeemMostPerPerson: file.RedeemMostPerPerson,
      redeemMostPerPersonInPeriod: file.RedeemMostPerPersonInPeriod,
      timeRounding: file.TimeRounding,
      hideCampaign: file.AppsVisibility,
      userLevel: file.UserLevel,
      userVisibility: file.UserVisibility,
      extra: file.Extra,
      pricePerUnit: file.PricePerUnit ? file.PricePerUnit : 0,
    }
    if (file.StoreType) {
      if (file.StoreType.toLowerCase() == 'point') {
        params.pointPerUnit = file.PointPerUnit
      }
    }
    if (file.img) {
      params.image = file.img
    }
    if (file.serialCodeFile) {
      params.serialCodeFile = file.serialCodeFile
    }
    console.log(params)
    var strUrl = this.config().bzbsModuleUrl + '/crmplusburn/'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postCRMPlusBurnCampaingsEdit: function (file) {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agencyId(),
      name: file.Name,
      nameEN: file.NameEN,
      detail: file.Detail,
      detailEN: file.DetailEN,
      termsAndConditions: file.TermsAndConditions,
      conditionEN: file.ConditionEN,
      quantity: file.Quantity,
      startdate: file.StartDate,
      enddate: file.EndDate,
      active: file.Status,
      redeemMostPerPerson: file.RedeemMostPerPerson,
      redeemMostPerPersonInPeriod: file.RedeemMostPerPersonInPeriod,
      timeRounding: file.TimeRounding,
      hideCampaign: file.AppsVisibility,
      userLevel: file.UserLevel,
      userVisibility: file.UserVisibility,
      extra: file.Extra,
    }
    if (file.StoreType) {
      if (file.StoreType.toLowerCase() == 'point') {
        params.pointPerUnit = file.PointPerUnit
      }
    }
    if (file.img) {
      params.image = file.img
    }
    if (file.serialCodeFile) {
      params.serialCodeFile = file.serialCodeFile
    }
    var strUrl = this.config().bzbsModuleUrl + '/crmplusburn/' + file.ID
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postCRMPlusBurnCampaingsDuplicate: function (file) {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agencyId(),
      startdate: file.StartDate,
      enddate: file.EndDate,
      active: file.Status,
      hideCampaign: file.AppsVisibility,
    }
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusburn/' +
      file.ID +
      '/renew_campaign'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postBurnCampaingsSequence: function (obj) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusburn/campaigns/sequence?agencyId=' +
      parseInt(this.agencyId()) +
      '&campaignJson=[' +
      obj +
      ']'
    return BzbsApi.connectBzbsPost(strUrl, this.token())
  },
  postBurnCampaingsDelete: function (obj) {
    let params = {
      agencyId: parseInt(this.agencyId()),
    }
    var strUrl =
      this.config().bzbsModuleUrl + '/crmplusburn/' + obj + '/cancel_campaign'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  getSerialCodeListExportExcel: function (campaignId) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusburn/' +
      campaignId.campaignID +
      '/serials?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agencyId() +
      '&top=0&skip=0'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  getCRMPlusEarnCampaings: function () {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agencyId(),
    }

    var strUrl = this.config().bzbsModuleUrl + '/crmplusearn'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  postCRMPlusCreateEarnCampaings: function (obj) {
    var params = {
      name: obj.name,
      description: obj.description,
      active: obj.active,
      startDate: obj.startDate ? obj.startDate : null,
      expiration: obj.expiration,
      pointRatio: obj.pointRatio,
      welcomePoint: obj.welcomePoint,
      expireDate: obj.expireDate ? obj.expireDate : null,
    }

    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusearn?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agencyId()
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postCRMPlusUpdateEarnCampaings: function (obj) {
    var params = {
      active: obj.active,
      ...obj,
    }
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusearn/' +
      obj.rowKey +
      '?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agencyId()
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  getCRMPlusStampCollection: function () {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agencyId(),
    }

    var strUrl = this.config().bzbsModuleUrl + '/crmplusstamp'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  getCRMPlusBurnCampaingsByAvailable: function (available) {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agencyId(),
      available: available,
    }

    var strUrl = this.config().bzbsModuleUrl + '/crmplusburn'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  postCRMPlusStampCollection: function (obj) {
    var params = {
      name: obj.name,
      detail: obj.detail,
      startDate: obj.startDate ? obj.startDate : null,
      expireDate: obj.expireDate ? obj.expireDate : null,
      pricePerStamp: obj.pricePerStamp.toString(),
      quantity: obj.quantity.toString(),
      active: obj.active.toString(),
      campaigns: obj.campaigns,
    }

    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusstamp?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agencyId()
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postCRMPlusUpdateStampCollection: function (obj) {
    var params = {
      name: obj.name,
      detail: obj.detail,
      startDate: obj.startDate ? obj.startDate : null,
      expireDate: obj.expireDate ? obj.expireDate : null,
      pricePerStamp: obj.pricePerStamp.toString(),
      active: obj.active.toString(),
    }

    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusstamp/' +
      obj.id +
      '?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agencyId()
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
}
