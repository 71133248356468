import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Cache from '@/helper/Cache.js'
import moment from 'moment'
/**
 * @method getCRMPlusStaff
 * @method getCRMPlusAdmin
 */
export default {
  KEY_COOKIE_USER: 'CRMUser',
  config: function () {
    return Vue.bzbsConfig
  },
  token: function () {
    return Cache.get(this.KEY_COOKIE_USER).Token
  },
  appId: function () {
    return Cache.get(this.KEY_COOKIE_USER).AppId
  },
  agency_id: function () {
    return Vue.$cookies.get(this.KEY_COOKIE_USER).AgencyId
      ? Vue.$cookies.get(this.KEY_COOKIE_USER).AgencyId
      : this.config().agency_id
  },
  lineObjToFlexJsonMessage(data) {
    let lineTemp = ''
    if ((data.text || data.link) && data.imgUrl) {
      lineTemp = {
        type: 'flex',
        altText: data.text,
        contents: {
          type: 'bubble',
        },
      }
      if (data.imgUrl) {
        lineTemp.contents.hero = {
          type: 'image',
          url: data.imgUrl,
          size: 'full',
          aspectRatio: '1:1',
          aspectMode: 'cover',
        }
      }
      if (data.text || data.link) {
        lineTemp.contents.body = {
          type: 'box',
          layout: 'vertical',
          contents: [
            {
              type: 'text',
              text: 'Campaign',
              weight: 'regular',
              size: 'xs',
              contents: [],
              wrap: true,
            },
          ],
        }
      }
      if (data.text) {
        lineTemp.contents.body.contents[0].contents.push({
          type: 'span',
          text: data.text + ' ',
        })
      }
      if (data.link) {
        lineTemp.contents.body.contents.push({
          type: 'button',
          action: {
            type: 'uri',
            label: 'See More',
            uri: data.link,
          },
          style: 'primary',
          margin: 'md',
        })
      }
    } else if ((data.text || data.link) && !data.imgUrl) {
      lineTemp = {
        type: 'text',
        text: data.text + ' : ' + data.link,
      }
    } else if (!data.text && !data.link && data.imgUrl) {
      lineTemp = {
        type: 'image',
        originalContentUrl: data.imgUrl,
        previewImageUrl: data.imgUrl,
      }
    }
    return lineTemp
  },
  objToImageMapMessage(data) {
    const returndata = []
    let texttemp = {
      type: 'text',
      text: data.text,
    }
    let imageMaptemp = {}
    if (data.link && !data.imgUrl) {
      texttemp.text = data.text + ' : ' + data.link
    }
    returndata.push(texttemp)
    if (data.imgUrl) {
      imageMaptemp = {
        type: 'imagemap',
        baseUrl: data.imgUrl.replace('/1040', ''),
        altText: data.text,
        baseSize: { width: 1040, height: 1040 },
        actions: [],
      }
      if (data.link) {
        imageMaptemp.actions = [
          {
            type: 'uri',
            linkUri: data.link,
            area: { x: 0, y: 0, width: 1040, height: 1040 },
          },
        ]
      }
      returndata.push(imageMaptemp)
    }
    return returndata
  },
  getSmarttargetBlob() {
    var urlblob =
      Vue.bzbsConfig.bzbsBlobUrl + '/config/crmplus/smart-target/target.json'
    return BzbsApi.connectBzbsGetBlob(urlblob)
  },
  getSmarttargetDCBlob() {
    var urlblob =
      Vue.bzbsConfig.bzbsBlobUrl + '/config/crmplus/smart-target/target.json'
    return BzbsApi.connectBzbsGetBlob(urlblob)
  },
  getArtWork() {
    var urlblob = Vue.bzbsConfig.bzbsModuleUrl + '/crmplussmarttarget/artwork'
    return BzbsApi.connectBzbsGet(urlblob)
  },
  getSmarttargetList() {
    const params = {
      device_app_id: this.config().client_id,
      app_id: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
      limit: 0,
    }
    console.log(params)
    var strUrl = this.config().bzbsEdmUrl + '/campaign/list'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  getSmarttargetListRedeem(ads_id) {
    const params = {
      app_id: this.appId(),
      ads_id,
    }
    let newqs = new URLSearchParams(params).toString()
    var strUrl =
      this.config().bzbsEdmUrl + '/campaign/' + ads_id + '/userredeem'
    return BzbsApi.connectBzbsGet(strUrl + '?' + newqs, this.token())
  },
  getSmarttargetListUsed(ads_id) {
    const params = {
      app_id: this.appId(),
      ads_id,
    }
    var strUrl = this.config().bzbsEdmUrl + '/campaign/' + ads_id + '/userused'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  getCampiagnDetail(id) {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
    }
    console.log(params)
    var strUrl = this.config().bzbsEdmUrl + '/campaign/' + id + '/detail'
    return BzbsApi.connectBzbsGet(strUrl, this.token())
  },
  getDetailSent(adsid) {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
    }
    console.log(params)
    var strUrl = this.config().bzbsEdmUrl + '/campaign/' + adsid + '/userdetail'
    return BzbsApi.connectBzbsGet(strUrl, this.token())
  },
  getDetailTotal(userlistId) {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
    }
    console.log(params)
    var strUrl =
      this.config().bzbsEdmUrl + '/userlist/' + userlistId + '/userdetail'
    return BzbsApi.connectBzbsGet(strUrl, this.token())
  },
  getDetailClick(adsid) {
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
    }
    console.log(params)
    var strUrl = this.config().bzbsEdmUrl + '/campaign/' + adsid + '/userclick'
    return BzbsApi.connectBzbsGet(strUrl, this.token())
  },
  sendTestToLine(lineid, data) {
    let lineTemp = this.objToImageMapMessage(data)
    const params = {
      app_id: Cache.get('CRMUser').AppId,
      sponsor_id: this.agency_id(),
      line_user_id: lineid,
      ads_id: 1471,
      json: JSON.stringify(lineTemp),
    }
    console.log(params)
    var strUrl = this.config().bzbsModuleUrl + '/line/send_test_message'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  sendTestSmartTarget(type, data) {
    var params = {}
    if (type.toLowerCase() == 'sms') {
      console.log(data)
      let message = {
        Message: data.Message,
        FullUrl: data.FullUrl,
      }
      params = {
        appId: Cache.get('CRMUser').AppId,
        receiver: data.sender,
        smsTemplate: JSON.stringify(message),
        type: 'sms',
      }
    } else {
      let tempEmail = {
        subject: data.subject,
        html: data.Message,
      }
      params = {
        appId: Cache.get('CRMUser').AppId,
        receiver: data.sender,
        emailTemplate: JSON.stringify(tempEmail),
        type: 'email',
      }
    }
    var strUrl = this.config().bzbsEdmUrl + '/main/send_test'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  createCampaign(data, userList, state, adsId) {
    let lineTemp = this.objToImageMapMessage(data)
    let userListData = [
      {
        userlistid: userList.ID,
        name: userList.Name,
        description: userList.Description,
        filtertype: 'csv',
        usercount: userList.UserCount,
      },
    ]
    var params = {}
    if (data.messageType) {
      if (data.messageType.toLowerCase() == 'email') {
        params = {
          sendType: 'email',
          app_id: Cache.get('CRMUser').AppId,
          agencyId: this.agency_id(),
          name: data.emailTemplate.Subject,
          description: 'description',
          emailTemplate: [JSON.stringify(data.emailTemplate)],
          userListsType: '1',
          schedule: data.schedule,
          repeatType: '',
          repeatStartDate: '',
          repeatEndDate: '',
          repeatTime: '',
          repeatDayOfWeek: '',
          repeatDayOfMonth: '',
          status: state,
          userlistitem: [JSON.stringify(userListData)],
          // lineMessages: JSON.stringify(lineTemp),
          actionType: data.actionType,
        }
      } else if (data.messageType.toLowerCase() == 'sms') {
        params = {
          sendType: 'sms',
          app_id: Cache.get('CRMUser').AppId,
          agencyId: this.agency_id(),
          name: this.handleNameSMS(data.smsTemplate.Message, data.actionType),
          description: 'description',
          smsTemplate: [JSON.stringify(data.smsTemplate)],
          userListsType: '1',
          schedule: data.schedule,
          repeatType: '',
          repeatStartDate: '',
          repeatEndDate: '',
          repeatTime: '',
          repeatDayOfWeek: '',
          repeatDayOfMonth: '',
          status: state,
          userlistitem: [JSON.stringify(userListData)],
          // lineMessages: JSON.stringify(lineTemp),
          actionType: data.actionType,
        }
      } else {
        params = {
          app_id: Cache.get('CRMUser').AppId,
          agencyId: this.agency_id(),
          name: data.text,
          description: 'description',
          userListsType: '1',
          schedule: data.schedule,
          repeatType: '',
          repeatStartDate: '',
          repeatEndDate: '',
          repeatTime: '',
          repeatDayOfWeek: '',
          repeatDayOfMonth: '',
          status: state,
          userlistitem: [JSON.stringify(userListData)],
          lineMessages: JSON.stringify(lineTemp),
          actionType: data.actionType,
        }
      }
    } else {
      params = {
        app_id: Cache.get('CRMUser').AppId,
        agencyId: this.agency_id(),
        name: data.text,
        description: 'description',
        userListsType: '1',
        schedule: data.schedule,
        repeatType: '',
        repeatStartDate: '',
        repeatEndDate: '',
        repeatTime: '',
        repeatDayOfWeek: '',
        repeatDayOfMonth: '',
        status: state,
        userlistitem: [JSON.stringify(userListData)],
        lineMessages: JSON.stringify(lineTemp),
        actionType: data.actionType,
      }
    }

    if (data.schedule === 'schedule') {
      params.scheduleDate = moment(data.dateTime.startDate)
        .utcOffset(0)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toISOString()
        .split('T')[0]
      params.scheduleTime = moment(data.dateTime.startTime).format('HH:mm:ss')
    }
    if (adsId) {
      params.adsId = adsId
    }
    console.log(params)
    var strUrl = this.config().bzbsEdmUrl + '/campaign/create'
    return BzbsApi.connectBzbsPostEncode(strUrl, this.token(), params)
  },
  createUserList(name, description, filename) {
    if (this.config().env == 'stg')
      filename = filename.split('/').join('-' + this.config().env + '/')
    let dateNow = {
      schedule: 'now',
      scheduleDate: moment()
        .utcOffset(0)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toISOString(),
      scheduleTime: moment().format('HH:mm:ss'),
    }
    const params = {
      device_app_id: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
      name: name,
      description: description['en'],
      schedule: JSON.stringify(dateNow),
      fileNameFromBlob: `/${filename + Cache.get('CRMUser').AppId}`,
    }
    var strUrl = this.config().bzbsEdmUrl + '/userlist/create'
    return BzbsApi.connectBzbsPostEncode(strUrl, this.token(), params)
  },
  updateCampaign(data, id, campaignId) {
    let lineTemp = this.lineObjToFlexJsonMessage(data)
    const asd = {
      campaignId: campaignId,
      campaignName: '',
      campaignDesc: '',
      imageType: '',
      imageUrl: '',
      order: 0,
      agencyName: '',
      campaignStart: '',
      campaignExpire: '',
      bizName: '',
      appId: this.config().client_id,
    }
    console.log(asd)
    const campaignItemList = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      name: data.text,
      description: 'description',
      userListsType: '1',
      schedule: data.schedule,
      repeatType: '',
      repeatStartDate: '',
      repeatEndDate: '',
      repeatTime: '',
      repeatDayOfWeek: '',
      repeatDayOfMonth: '',
      status: 'publish',
      lineTemplate: JSON.stringify(lineTemp),
    }
    if (data.schedule === 'schedule') {
      campaignItemList.scheduleDate = moment(data.dateTime.startDate)
        .utcOffset(0)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toISOString()
      campaignItemList.scheduleTime = moment(data.dateTime.startTime).format(
        'HH:mm:ss',
      )
    }
    const params = {
      device_app_id: this.config().client_id,
      agencyId: this.agency_id(),
      adsid: id,
      campaignItem: [JSON.stringify(campaignItemList)],
    }
    var strUrl = this.config().bzbsEdmUrl + '/campaign/update_campaigns'
    return BzbsApi.connectBzbsPostEncode(strUrl, this.token(), params)
  },
  deleteCampiagn(id) {
    const params = {
      device_app_id: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
    }
    var strUrl = this.config().bzbsEdmUrl + '/campaign/' + id + '/cancel'
    return BzbsApi.connectBzbsPostEncode(strUrl, this.token(), params)
  },
  upLoadImg(image) {
    const params = {
      device_app_id: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
      image,
    }
    var strUrl =
      this.config().bzbsModuleUrl + '/crmplussmarttarget/artwork_imagemap'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  upLoadImgURL(imageUrl) {
    const params = {
      device_app_id: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
      imageUrl,
    }
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplussmarttarget/artwork_imagemap_from_url'
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  export(id) {
    const params = {
      device_app_id: Cache.get('CRMUser').AppId,
      agencyId: this.agency_id(),
    }
    var strUrl = this.config().bzbsEdmUrl + '/campaign/' + id + '/export'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  handleNameSMS(message, action) {
    if (action == 'Exitinglink') {
      return message
    } else {
      return message.split('<p><a href="')[0]
    }
  },
}
