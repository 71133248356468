<template>
  <div :class="['mainBG', isOpenModal ? 'open' : '']">
    <div class="mainModal">
      <div class="icon">
        <img
          src="@/assets/images/Done-circle2.svg"
          v-if="modalType == 'success'"
          alt=""
          width="100"
          height="100"
        />
        <img
          src="@/assets/images/Error-circle.svg"
          v-if="modalType == 'error'"
          alt=""
          width="100"
          height="100"
        />
      </div>
      <div class="titleText">{{ title }}</div>
      <div class="details">{{ details }}</div>
      <div v-if="desciption" class="details">{{ desciption }}</div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
export default {
  mixins: [Mixin],
  props: {
    title: {
      type: String,
    },
    details: {
      type: String,
    },
    desciption: {
      type: String,
      default: null
    },
    type: {
      type: String,
    },
    modalType: {
      type: String,
    },
    isOpenModal: {
      type: Boolean,
    },
    onActionModal: {
      type: Function,
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.mainBG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1256;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
  &.open {
    visibility: visible;
    opacity: 1;
  }
  .mainModal {
    max-width: 310px;
    width: 100%;
    background-color: #eeeeee;
    border-radius: 12px;
    padding: 30px;
    text-align: center;
    .titleText {
      margin-left: 8px;
      font-size: 24px;
      color: #424242;
      font-weight: 600;
    }
    .details {
      margin-top: 10px;
      font-size: 14px;
    }
  }
}
</style>
