<template>
  <div>
    <div class="search-box d-flex justify-content-between">
      <div>
        <p class="mb-0 pagination">
          {{ lbl['pagination-show-text'] }}
          {{ dataDetailPerPage }}
          {{ lbl['from'] }}
          {{ dataDetailLength }}
          {{ lbl['smarttarget-Items'] }}
        </p>
      </div>
      <div>
        <button class="exprtBtn" @click="onExportFE">Export</button>
      </div>
    </div>
    <!-- Table -->
    <div class="pt-3 table-box selectrewardTable">
      <a-table
        row-key="UserID"
        :columns="columndetailState"
        :data-source="data"
        :pagination="{ defaultPageSize: 5 }"
        :bordered="false"
        @change="onchangeTable"
      >
        <template slot="name" slot-scope="text, record">
          {{ record.FirstName ? record.FirstName : record.Name }}{{ ' '
          }}{{ record.LastName ? record.LastName : null }}
        </template>
      </a-table>
    </div>
    <!-- Table -->
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import BzbsSmarttarget from '@/core/Smarttarget/service/BzbsSmarttarget'
import XLSX from 'xlsx'

export default {
  name: 'userCustomerLog',
  mixins: [Mixin],
  props: {
    data: {
      type: Array,
    },
    id: {
      type: String,
    },
    columnsDetail: {
      type: Array,
    },
  },
  data: function () {
    return {
      total: null,
      dataDetail: [],
      totalDetail: null,
      currentPage: 1,
      filters: {
        user_id: '',
      },
      storetype: '',
    }
  },
  computed: {
    columndetailState() {
      return this.columnsDetail
    },
    dataDetailLength() {
      return this.data.length
    },
    dataDetailPerPage() {
      let lastItemPage = this.dataDetailLength % 5
      let lastPage = Math.ceil(this.dataDetailLength / 5)
      if (this.currentPage === lastPage) {
        return lastItemPage
      }
      return this.dataDetailLength < 5 ? this.dataDetailLength : 5
    },
  },
  created() {},
  mounted() {
    console.log(this.columnsDetail)
    console.log(this.data)
  },
  methods: {
    onchangeTable(e) {
      this.currentPage = e.current
    },
    onExportFE() {
      console.log(this.data)
      if (this.data.length <= 10000) {
        const worksheet = XLSX.utils.json_to_sheet(this.data)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Details')
        let date = Date.parse(new Date())
        XLSX.writeFile(workbook, `User_Details_${date}.xlsx`)
      } else {
        for (var i = 0; i < Math.ceil(this.data.length / 10000); i++) {
          var data_WS = XLSX.utils.json_to_sheet(
            this.data.slice((i + 1 - 1) * 10000, (i + 1) * 10000),
            { dense: true },
          )
          const workbook = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(workbook, data_WS, 'Details')
          let date = Date.parse(new Date())
          XLSX.writeFile(
            workbook,
            `User_Details_` + (i + 1) + '_' + `${date}.xlsx`,
          )
        }
      }
    },
    onExport() {
      BzbsSmarttarget.export(this.id)
        .then(res => {
          console.log('export', res.data.data.data.file_url)
          const link = document.createElement('a')
          link.href = res.data.data.data.file_url
          link.download = 'UserExport.xlsx'
          link.click()
        })
        .catch(error => {
          console.log('apiGetAppCategoryList error', error)
        })
    },
  },
}
</script>
<style lang="scss"></style>
